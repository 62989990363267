/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.togglenavi {
  display: none;
}
.navi,
.navi > .item {
  float: left;
  display: block;
}
div.sub1 {
  width: 100%;
}
.menu {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
  font-family: 'Arvo', sans-serif;
  padding: 16px 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #000;
}
a.path,
.menu:hover,
.menu:focus {
  color: #fff;
  background: #0b1408;
  text-decoration: none;
}
.menu:active {
  background-color: #000;
}
.sub1 > .item {
  z-index: 2;
  position: relative;
}
.sub1 > .item > .menu {
  border-right: 1px solid #fff;
}
.sub1 > .exit > .menu {
  border: 0;
}
div.sub2 {
  position: absolute;
  top: 100%;
  width: 200px;
  background: #EDEDED;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: none;
}
.item:hover > div.sub2 {
  display: block;
}
.sub2 > .item {
  display: block;
  width: 100%;
}
div.sub2 .menu {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
#navigation .sub3 {
  display: none;
}
.desk > .sub3 {
  width: 100%;
  background: #fff;
  background: rgba(255, 255, 255, 0.97);
}
.dock {
  display: block;
  float: right;
  color: #999;
  margin-top: 32px;
}
.meta,
.meta:visited {
  color: #999;
  line-height: 1;
  font-size: 12px;
  font-family: 'Arvo', sans-serif;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.meta:hover,
.meta:focus {
  color: #000;
}
#cmsbox {
  float: left;
  padding: 0;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  width: 980px;
  max-width: none;
}
#head {
  width: 620px;
  margin: 20px 0 0 4%;
}
#slides .slide,
#expo {
  width: 980px;
}
#wrapper {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  background-attachement: fixed;
  background-position: bottom;
}
#navigation {
  width: 100%;
  float: left;
  background: #fff;
  background: rgba(255, 255, 255, 0.94);
}
#content {
  margin-top: 60px;
}
.contentbg {
  margin-top: 5px;
  float: left;
  width: 100%;
  padding-bottom: 40px;
}
#maincontent {
  width: 620px;
  background: #fff;
  background: rgba(255, 255, 255, 0.97);
}
.side {
  background: #fff;
  background: rgba(255, 255, 255, 0.97);
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 46%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4.17391304%;
  margin-left: 4.17391304%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 91.65217391%;
}
.area > .slim .tiny {
  width: 91.65217391%;
}
.side {
  width: 360px;
}
.side > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 360px;
}
.side h2,
.side .foot {
  margin-right: 30px;
  margin-left: 30px;
}
.side .part,
.side > .grid table {
  margin-right: 30px;
  margin-left: 30px;
  width: 300px;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 300px;
}
.side > .slim h2,
.side > .slim .foot,
.side > .slim .part,
.side > .slim.grid table {
  margin-right: 30px;
  margin-left: 30px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.wide.resp .swap > table {
  float: left;
  width: 70% !important;
}
.wide.resp > .foot > .scan > .ctrl {
  margin-top: 0;
}
.wide p.topi {
  float: left;
  width: 60%;
}
.wide .resp > .link {
  text-align: right;
}
.wide .resp > .link > .open {
  float: left;
  width: 60%;
  text-align: left;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
div.side {
  float: right;
  padding-top: 60px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
#disp a.capt {
  width: 50%;
}
#disp input.capt {
  float: right;
  width: 48%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.cb-photoeditor {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.cb-lightbox-code {
  left: 50%;
  margin-left: -240px;
  width: 480px;
}
#disp.shop,
#disp.cb-shoppingcart {
  left: 50%;
  margin-left: -265px;
  width: 530px;
}
h1,
h2 {
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}
.cb-strips-4 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 18% !important;
}
.cb-strips-6 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 10.5% !important;
}
.cb-strips-8 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 6.5% !important;
}
.cb-strips-10 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 4% !important;
}
.cb-matrix-columns-1 .part.foto {
  margin: 3% !important;
  width: 94% !important;
}
.cb-matrix-columns-2 .part.foto {
  margin: 3% !important;
  width: 44% !important;
}
.cb-matrix-columns-3 .part.foto {
  margin: 3% !important;
  width: 27% !important;
}
.cb-matrix-columns-4 .part.foto {
  margin: 3% !important;
  width: 19% !important;
}
.cb-matrix-columns-5 .part.foto {
  margin: 3% !important;
  width: 14% !important;
}
.cb-matrix-columns-6 .part.foto {
  margin: 3% !important;
  width: 10.5% !important;
}
.cb-matrix-columns-7 .part.foto {
  margin: 3% !important;
  width: 8% !important;
}
.cb-matrix-columns-8 .part.foto {
  margin: 3% !important;
  width: 6.5% !important;
}
.cb-strips-thumbs .body {
  width: 100%;
}
.cb-slideshow .part {
  height: 100% !important;
}
.crossslide img {
  max-height: 100% !important;
}
.cb-strips-images {
  width: 100%;
  height: 100%;
}
.cb-album .cb-strips-images a.foto {
  width: 100%;
  height: 100%;
}
.cb-album .cb-strips-images .cb-portrait {
  max-height: 100% !important;
  width: auto !important;
}
.cb-album .cb-strips-thumbs .part img {
  opacity: 1 !important;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #c1e2b3;
  color: #254118;
}
.same,
.mese .same > span {
  background-color: #c1e2b3;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #85c669;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 18px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url(/icons/prev_next.png);
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -54px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -18px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -36px;
}
.cb-album .next {
  background-position: -17px -54px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -17px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -17px -18px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -17px -36px;
}
.cb-manual > .head > h2,
#edit .cb-slideshow > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
/*# sourceMappingURL=./screen-large.css.map */